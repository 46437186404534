import {forwardRef, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useCdnUrl from "../hooks/cdn-hook";
import {WebsiteContext} from "../index";

export const Image = forwardRef(function Image(
    {
        id,
        imageId,
        imageWidth,
        imageHeight,
        lazy,
        src,
        pictureClassName,
        className,
        quality,
        width,
        height,
        alt,
        layout,
        style,
        onClick,
        cdnUrl
    },
    ref
) {
    const internalRef = useRef();
    const [visible, setVisible] = useState(false);
    const [webpSupported, setWebpSupported] = useState(true);
    const context = useContext(WebsiteContext);

    const fallbackSrc =
        src ||
        "https://dr1k2g3wmnols.cloudfront.net/images/image-placeholder.png";

    // Combine internal and forwarded refs
    const combinedRef = (node) => {
        internalRef.current = node;
        if (typeof ref === "function") {
            ref(node);
        } else if (ref) {
            ref.current = node;
        }
    };

    // Check for WebP support
    useEffect(() => {
        const elem = document.createElement("canvas");
        const supported =
            !!(elem.getContext && elem.getContext("2d")) &&
            elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        setWebpSupported(supported);
    }, []);

    // Synchronously check if the image is in view on mount
    useLayoutEffect(() => {
        if (internalRef.current) {
            const rect = internalRef.current.getBoundingClientRect();
            if (rect.top < window.innerHeight && rect.bottom > 0) {
                setVisible(true);
            }
        }
    }, []);

    // Use IntersectionObserver to set visibility if image comes into view later
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                    observer.disconnect();
                }
            },
            {threshold: context?.picker ? 0 : 0.1}
        );

        if (internalRef.current) {
            observer.observe(internalRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const imgClass = classNames(
        "transition-opacity duration-700 ease-out transform",
        visible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2",
        className
    );

    // Conditionally set the loading attribute based on visibility:
    // "eager" if visible on page load, otherwise "lazy".
    const loadingAttr = visible ? "eager" : "lazy";

    if (fallbackSrc.endsWith(".svg")) {
        return (
            <img
                onClick={onClick}
                id={imageId}
                ref={combinedRef}
                className={imgClass}
                width={imageWidth}
                height={imageHeight}
                loading={lazy ? lazy : loadingAttr}
                src={useCdnUrl(fallbackSrc, null, null, null, quality, cdnUrl)}
                alt={alt ?? ""}
                style={style}
            />
        );
    }

    return (
        <picture id={id} className={pictureClassName}>
            {layout === "fixed" &&
                (webpSupported ? (
                    <source
                        type="image/webp"
                        srcSet={`
                            ${useCdnUrl(src, "webp", width, height, quality, cdnUrl)} 1x,
                            ${useCdnUrl(src, "webp", width * 1.75, height, quality, cdnUrl)} 1.75x,
                            ${useCdnUrl(src, "webp", width * 2, height, quality, cdnUrl)} 2x,
                            ${useCdnUrl(src, "webp", width * 3, height, quality, cdnUrl)} 3x
                        `}
                    />
                ) : (
                    <source
                        type="image/jpeg"
                        srcSet={`
                            ${useCdnUrl(src, null, width, height, quality, cdnUrl)} 1x,
                            ${useCdnUrl(src, null, width * 1.75, height, quality, cdnUrl)} 1.75x,
                            ${useCdnUrl(src, null, width * 2, height, quality, cdnUrl)} 2x,
                            ${useCdnUrl(src, null, width * 3, height, quality, cdnUrl)} 3x
                        `}
                    />
                ))}

            {layout === "responsive" &&
                (webpSupported ? (
                    <>
                        <source
                            type="image/webp"
                            media="(max-width: 640px)"
                            srcSet={`
                                ${useCdnUrl(src, "webp", 416, null, quality, cdnUrl)} 1x,
                                ${useCdnUrl(src, "webp", 832, null, quality, cdnUrl)} 2x
                            `}
                        />
                        <source
                            type="image/webp"
                            media="(max-width: 1350px)"
                            srcSet={`
                                ${useCdnUrl(src, "webp", 1350, null, quality, cdnUrl)} 1x,
                                ${useCdnUrl(src, "webp", 2700, null, quality, cdnUrl)} 2x
                            `}
                        />
                        <source
                            type="image/webp"
                            media="(min-width: 1351px)"
                            srcSet={`
                                ${useCdnUrl(src, "webp", 1920, null, quality, cdnUrl)} 1x,
                                ${useCdnUrl(src, "webp", 3840, null, quality, cdnUrl)} 2x
                            `}
                        />
                    </>
                ) : (
                    <>
                        <source
                            type="image/jpeg"
                            media="(max-width: 640px)"
                            srcSet={`
                                ${useCdnUrl(src, null, 416, null, quality, cdnUrl)} 1x,
                                ${useCdnUrl(src, null, 832, null, quality, cdnUrl)} 2x
                            `}
                        />
                        <source
                            type="image/jpeg"
                            media="(max-width: 1350px)"
                            srcSet={`
                                ${useCdnUrl(src, null, 1350, null, quality, cdnUrl)} 1x,
                                ${useCdnUrl(src, null, 2700, null, quality, cdnUrl)} 2x
                            `}
                        />
                        <source
                            type="image/jpeg"
                            media="(min-width: 1351px)"
                            srcSet={`
                                ${useCdnUrl(src, null, 1920, null, quality, cdnUrl)} 1x,
                                ${useCdnUrl(src, null, 3840, null, quality, cdnUrl)} 2x
                            `}
                        />
                    </>
                ))}

            <img
                onClick={onClick}
                id={imageId}
                ref={combinedRef}
                className={imgClass}
                width={imageWidth}
                height={imageHeight}
                loading={lazy ? lazy : loadingAttr}
                src={useCdnUrl(fallbackSrc, null, null, null, quality, cdnUrl)}
                alt={alt ?? ""}
                style={style}
            />
        </picture>
    );
});

Image.propTypes = {
    id: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    pictureClassName: PropTypes.string,
    className: PropTypes.string,
    quality: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    layout: PropTypes.oneOf(["fixed", "responsive"]),
};

Image.defaultProps = {
    quality: 75,
    layout: "fixed",
    width: 720
};
