import {createContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';

import Theme from "./theme";
import BlockList from "./blocks";
import SiteNavigationBlock from "./blocks/site-navigation-block/";
import FooterNavigationBlock from "./blocks/footer-navigation-block";

import SingleColumn from "./layouts/single-column";
import MultiColumn from "./layouts/multi-column";
import Blog from "./layouts/blog";

export const WebsiteContext = createContext({
    cdnUrl: '',
    fileDownloadUrl: '',
    builder: false,
    breakpoint: 640,
    picker: false,
    env: '',
    channel: '',
    node: '',
});

Website.propTypes = {
    className: PropTypes.string,
    cdnUrl: PropTypes.string.isRequired,
    fileDownloadUrl: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired,
    channel: PropTypes.string,
    node: PropTypes.object,
    builder: PropTypes.bool,
    picker: PropTypes.bool,
    header: PropTypes.shape({
        title: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
    }),
    footer: PropTypes.shape({
        items: PropTypes.array.isRequired,
    }),
    theme: PropTypes.object.isRequired,
    blocks: PropTypes.array.isRequired,
    blockActions: PropTypes.bool,
    onBlockAction: PropTypes.func,
    onBlockSelected: PropTypes.func,
    selectedBlock: PropTypes.string,
    onClick: PropTypes.func,
};

Website.defaultProps = {
    blockActions: false,
    onBlockAction: () => {},
    breakpoint: 640
};

export default function Website(
    {
        className,
        header,
        footer,
        social,
        unlinked,
        productCategories,
        schema,
        theme,
        blocks,
        blockActions,
        isEditing,
        onBlockAction,
        onBlockSelected,
        selectedBlock,
        onClick,
        children,
        rootPage,
        ...props
    }
) {
    const pageRef = useRef();
    // Build a lookup of all nodes that we can navigate to
    const lookup = useMemo(() => {
        const items = [].concat(header?.items || [], footer?.items || [], unlinked || [], productCategories || []);
        return items.reduce(function reducer(result, item) {
            if (item.action?.type === 'link' || item.action?.type === 'page') {
                const uuid = item._id ?? '';
                result[uuid] = item.action.value;
            } else if (item.nodeType === "ProductCategory") {
                const uuid = item.uuid ?? '';
                result[uuid] = item.uri;
            }

            if (item.children?.length) {
                item.children.reduce(reducer, result);
            }

            return result;
        }, {})
    }, [header, footer, unlinked]);

    // The rest of the props are for the website context
    const context = useMemo(() => (
        {
            ..._cloneDeep(props),
            blocks,
            theme,
            isEditing,
            rootPage,
            schema,
            actionLookup: lookup
        }
    ), [theme, isEditing, rootPage]);

    useEffect(() => {
        const footerHeight = document.getElementById("block_footer")?.offsetHeight
        document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
    }, [blocks])

    const [isNavigationSettings, setIsNavigationSettings] = useState(false)

    useEffect(() => {
        setIsNavigationSettings(window.location.pathname.includes('/navigation'));
    }, []);

    let PageLayout = SingleColumn;

    if (props.node?.nodeType === 'blog') {
        PageLayout = Blog;
    } else if (rootPage && (props.node?.nodeType !== 'folder' || props.node.nodeType !== 'action')) {
        PageLayout = MultiColumn;
    }

    return (
        <div ref={pageRef}
             className={classNames("h-full preview mx-auto flex flex-col",
                 `heading_${context.theme.selectedFont.headingName.split(' ').join('_').toLowerCase()}`,
                 `body_${context.theme.selectedFont.bodyName.split(' ').join('_').toLowerCase()}`,
                 context.builder && !isNavigationSettings ? "min-h-[calc(100vh-var(--footer-height))] sm:min-h-[calc(100vh-var(--default-app-bar-height)-var(--footer-height))]" : "min-h-screen",
                 context.builder && isNavigationSettings ? "min-h-[100px]" : null,
                 className
             )}
             onClick={onClick}>
            <Theme settings={theme}/>
            <WebsiteContext.Provider value={context}>
                {header && !["checkout"].includes(props?.pageType) ? (
                    <SiteNavigationBlock actions={blockActions} onAction={onBlockAction} {...header}
                                         settings={theme.header}/>
                ) : null}
                <PageLayout>
                    {blocks.length > 0 ? (
                        <BlockList
                            pageRef={pageRef}
                            blocks={blocks}
                            actions={blockActions}
                            onAction={onBlockAction}
                            onSelect={onBlockSelected}
                            selectedBlock={selectedBlock}
                            websiteContext={context}
                        />
                    ) : context.builder ? (
                        <>
                            {!isNavigationSettings ?
                                <div
                                    className={"flex flex-col items-center justify-center text-center placeholder-page relative grow p-6"}>
                                    <h4 className={"reset-font !font-bold"}>Create a new page using Page Assistant or
                                        start
                                        with a blank page or template</h4>
                                </div>
                                :
                                <div className={"h-48"}>
                                </div>
                            }
                        </>
                    ) : null}
                </PageLayout>
                {footer && !["checkout"].includes(props?.pageType) ? (
                    <FooterNavigationBlock actions={blockActions} onAction={onBlockAction} id="footer"
                                           hideInlineAddBlockButton={true} {...footer} settings={theme.footer}
                                           baseColour={theme.baseColour}/>
                ) : null}
                {children}
            </WebsiteContext.Provider>
        </div>
    );
};